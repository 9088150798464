import React from "react";


type HowToPlayModalProps = {
    setShowModal: (showModal: boolean) => void;
    showModal: boolean;
};
export default function HowToPlayModal(
    { setShowModal, showModal }: HowToPlayModalProps
) {
    return (
        <>
            {showModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                    <h3 className="text-3xl font-semibold">
                                        How To Play
                                    </h3>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <div>
                                        <h3 className="text-xl font-semibold">Objective</h3>
                                        <p className="text-lg font-normal">
                                            The objective of the game is to sink all of your opponent's ships before they sink yours.
                                        </p>
                                    </div>
                                    <div>
                                        <h3 className="text-xl font-semibold">Setup</h3>
                                        <p className="text-lg font-normal">
                                            Each player places their ships on their own grid. The ships can be placed horizontally or vertically, but not diagonally. The ships cannot overlap or touch each other. The ships are as follows:
                                            <ul>
                                                <li>1 Aircraft Carrier (4 spaces)</li>
                                                <li>1 Battleship (3 spaces)</li>
                                                <li>1 Cruiser (2 spaces)</li>
                                                <li>1 Submarine (2 spaces)</li>
                                                <li>1 Destroyer (1 spaces)</li>
                                            </ul>
                                        </p>
                                    </div>
                                    <div>
                                        <h3 className="text-xl font-semibold">Gameplay</h3>
                                        <p className="text-lg font-normal">
                                            Players take turns firing at their opponent's grid. If a ship is hit, the player will be notified. If a ship is sunk, the player will also be notified. The game continues until one player has sunk all of their opponent's ships.
                                        </p>
                                    </div>
                                    <div>
                                        <h3 className="text-xl font-semibold">Winning</h3>
                                        <p className="text-lg font-normal">
                                            The game is won when one player has sunk all of their opponent's ships.
                                        </p>
                                    </div>
                                </div>
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    );
}