import React, { useEffect, useState } from 'react'
import useGameStore from '../store/useGameStore'
import SpectatorScoreboard from './SpectatorScoreboard'

type SpectatorViewBoardProps = {
    playerName: string
    boardData?: number[][]
}

const SpectatorViewBoard: React.FC<SpectatorViewBoardProps> = ({
    playerName,
    boardData
}) => {
    const spectatorViewAttack = useGameStore(state => state.spectatorViewAttack);
    const numArray = Array.from({ length: 8 }, (_, i) => i + 1);
    const letterArray = Array.from({ length: 8 }, (_, i) => String.fromCharCode(65 + i));
    const zeroBoard = Array.from({ length: 8 }, () => Array.from({ length: 8 }, () => 0));
    const [board, setBoard] = useState(zeroBoard);
    const [score, setScore] = useState(0);
    useEffect(() => {
        if (spectatorViewAttack.length > 0 && spectatorViewAttack[0] !== playerName) {
            const newboard = [...board];
            newboard[spectatorViewAttack[1][0]][spectatorViewAttack[1][1]] = spectatorViewAttack[2];
            setBoard(newboard);

        }
        if (spectatorViewAttack[0] === playerName) {
            setScore((spectatorViewAttack[3] ? spectatorViewAttack[3] : 0));
        }
    }, [spectatorViewAttack,board,playerName]);
    return (
        <div className='flex flex-col items-center justify-center'>
            <h1 className='text-3xl font-bold'>{playerName}</h1>

            <div className='flex gap-10 justify-center items-center' >
                <div className='flex flex-col justify-center items-center'>
                    <div className='flex flex-row ml-[5vh] mr-[5vh]'>
                        {letterArray.map((letter, index) => (
                            <label key={index} className={`flex justify-center items-center w-[5vh] h-[5vh] ${'text-[#858585]'}`}>{letter}</label>
                        ))}
                    </div>
                    <div className='flex'>
                        <div className='flex flex-col'>
                            {numArray.map((t, index) => (
                                <label key={index} className={`flex justify-center items-center w-[5vh] h-[5vh]
                                ${'text-[#858585]'}`}>{t}</label>
                            ))}
                        </div>
                        <div>
                            {board?.map((row, rowIndex) => (
                                <div className='flex justify-center items-center' key={rowIndex}>
                                    {row.map((column, columnIndex) => (
                                        <button
                                            key={columnIndex} className={`flex justify-center items-center  w-[5vh] h-[5vh] bg-[#e8e8e8]/30 border border-[#858585] border-collapse
                                            `}>
                                            {
                                                column === 1 ? '' : column === 2 ?
                                                    <div className='w-full h-full bg-[#BFBFBF] hover:bg-none flex items-center justify-center' >
                                                        <div className='text-black font-extrabold text-[3vw]'>
                                                            <p>
                                                                &#10539;
                                                            </p>
                                                        </div>
                                                    </div>
                                                    : column === 3 ? <div className='w-full h-full bg-[#BFBFBF] flex items-center justify-center' />
                                                        : ''
                                            }
                                        </button>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <SpectatorScoreboard playerName={playerName} score={score} />
        </div>

    )
}

export default SpectatorViewBoard
