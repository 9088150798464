
import useUserNameStore from "../store/useUserNameStore"
import useStageStore from "../store/useStageStore";
import { joinRoom, startConnection } from "../socket";
const JoinButton = () => {
    const name = useUserNameStore(state => state.name);
    const stage = useStageStore(state => state.stage);
    const incrementStage = useStageStore(state => state.incrementStage);
    return (
        <button
            onClick={() => {
                startConnection();
                incrementStage();
                joinRoom(name);
            }}
            disabled={name.length === 0 || stage !== 1}
            className="w-[25%] p-2 bg-[#256EA3] rounded-2xl text-[#fff]"
        >
            ENTER GAME
        </button >)
}

export default JoinButton