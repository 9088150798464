/* eslint-disable */

import { useEffect } from 'react';
import {
  changeTurn,
  changeTurnListener,
  disconnectSocket,
  gameFinishListener,
  gameReadyListener,
  initiateSocketConnection,
  myAttackListener,
  receiveAttackListener,
  receiveBoardLister,
  removeGameReadyListener,
  removeReceiveAttackListener,
  removeReceiveBoardListener,
  removeSpectatorListener,
  removeUserListener,
  spectatorJoinedListener,
  userJoinedListener,
  removeMyAttackListener,
  removeChangeTurnListener,
  removeGameFinishListener,
  removeUpdateSpecBoardsListener,
  updateSpecBoardsListener,
} from './socket';
import StageOne from './components/StageOne';
import useStageStore from './store/useStageStore';
import StageTwo from './components/StageTwo';
import StageThree from './components/StageThree';
import useGameStore from './store/useGameStore';
import useBoardStore from './store/useBoardStore';
import Header from './components/Header';

function App() {

  const stage = useStageStore(state => state.stage);
  const setIsPlayer = useGameStore(state => state.setIsPlayer);
  const setEnemyBoard = useBoardStore(state => state.setEnemyBoard);
  const setIsGameReady = useGameStore(state => state.setIsGameReady);
  const setPlayer = useGameStore(state => state.setPlayer);
  const player = useGameStore(state => state.player);
  const setTurn = useGameStore(state => state.setTurn);
  const enemyBoard = useBoardStore(state => state.enemyBoard);
  const lastAttacked = useGameStore(state => state.lastAttacked);
  const setLastAttacked = useGameStore(state => state.setLastAttacked);
  const setWinner = useGameStore(state => state.setWinner);
  const setEnemy = useGameStore(state => state.setEnemy)
  const setEnemyScore = useGameStore(state => state.setEnemyScore);
  const enemyScore = useGameStore(state => state.enemyScore);
  const hitCount = useGameStore(state => state.hitCount);
  const setHitCount = useGameStore(state => state.setHitCount);
  const myAttack = useGameStore(state => state.myAttack);
  const setMyAttack = useGameStore(state => state.setMyAttack);
  const turn = useGameStore(state => state.turn);
  const setPlayersInfo = useGameStore(state => state.setPlayersInfo);
  const playersInfo = useGameStore(state => state.playersInfo);
  const setSpectatorViewAttack = useGameStore(state => state.setSpectatorViewAttack);
  const setSpectatorModePlayers = useGameStore(state => state.setSpectatorModePlayers);
  const setMyBoard = useGameStore(state => state.setMyBoard);
  const myBoard = useGameStore(state => state.myBoard);
  useEffect(() => {
    initiateSocketConnection();
    return () => {
      disconnectSocket();
    }
  }, []);

  useEffect(() => {
    if (lastAttacked) {
      const newboard = [...myBoard];
      newboard[lastAttacked[1][0]][lastAttacked[1][1]] = lastAttacked[0];
      setMyBoard(newboard);
      setEnemyScore(enemyScore + (lastAttacked[0] === 2 ? 1 : 0));
    }
    // eslint-disable-next-line
  }, [lastAttacked]);

  useEffect(() => {
    if (myAttack) {
      const newboard = [...enemyBoard];
      newboard[myAttack[1][0]][myAttack[1][1]] = myAttack[0];
      setEnemyBoard(newboard);
      setHitCount(hitCount + (myAttack[0] === 2 ? 1 : 0));
      if (myAttack[0] == 3) {
        changeTurn(turn === "1" ? "2" : "1")
      }
    }
    // eslint-disable-next-line
  }, [myAttack]);

  useEffect(() => {
    if (playersInfo.length > 1) {
      setEnemy(playersInfo[0] === player ? playersInfo[3] : playersInfo[1]);
    }
    // eslint-disable-next-line
  }, [playersInfo]);

  useEffect(() => {
    userJoinedListener(
      (data: any) => {
        setIsPlayer(true);
        setPlayer(data[0]);
      }
    );
    spectatorJoinedListener(
      (data: any) => {
        setIsPlayer(false);
        setSpectatorModePlayers(data);

      }
    );
    updateSpecBoardsListener(
      (data: any) => {
        setSpectatorViewAttack(data);
      }
    );

    gameFinishListener(
      (data: any) => {
        setWinner(data);
      }
    );
    gameReadyListener(
      (data: any) => {
        setIsGameReady(true);
        setPlayersInfo(data);
      }
    );

    receiveBoardLister(
      (data: number[][]) => {
        setMyBoard(data);
      }
    );
    receiveAttackListener(
      (data: any) => {
        setLastAttacked(data);
      }
    );
    myAttackListener(
      (data: any) => {
        setMyAttack(data);
      }
    )

    changeTurnListener(
      (data: string) => {
        setTurn(data);
      }
    );

    return () => {
      removeUserListener();
      removeSpectatorListener();
      removeReceiveBoardListener();
      removeReceiveAttackListener();
      removeGameReadyListener();
      removeGameReadyListener();
      removeChangeTurnListener();
      removeGameFinishListener();
      removeUpdateSpecBoardsListener();
      removeMyAttackListener();
    }
    // eslint-disable-next-line
  }, []);



  return (
    <main className=' flex flex-col justify-start  items-center h-screen w-screen relative overflow-hidden'>
      <Header />
      {
        stage === 1 ? <StageOne /> : stage === 2 ? <StageTwo /> : <StageThree />
      }
    </main>
  );
}

export default App;
