export const handleBoardClick =
  (row: number, column: number, shipType: number, board: number[][], placedShips: number[], setBoard: (board: number[][]) => void, setPlacedShips: (placedShips: number[]) => void) => {
    let newBoard: number[][] = [...board];
    newBoard.forEach((r: number[], rowIndex) => {
      r.forEach((c, columnIndex) => {
        if (row === rowIndex && column === columnIndex && c === 0) {
          switch (shipType) {
            case 1:
              if (column !== 0 && column !== 6 && column !== 7 && board[row][column] === 0 && board[row][column + 1] === 0 && board[row][column - 1] === 0 && board[row][column + 2] === 0) {
                for (let i = -2; i < 4; i++) {
                  if (row !== 0 && column < 5) {
                    newBoard[row - 1][column + i] = -1;
                  }
                  if (1 <= row && row <= 6 && column === 5 && i !== 3) {
                    newBoard[row + 1][column + i] = -1;
                    newBoard[row - 1][column + i] = -1;
                  }
                  if (row !== 7 && column < 5) {
                    newBoard[row + 1][column + i] = -1;
                  }
                  if (row === 7 && column === 5 && i !== 3) {
                    newBoard[row - 1][column + i] = -1;
                  }
                  if (row === 0 && column === 5 && i !== 3) {
                    newBoard[row + 1][column + i] = -1;
                  }
                  if (column < 5) {
                    newBoard[row][column + i] = -1;
                  } else {
                    newBoard[row][column - 2] = -1;
                  }
                }
                for (let i = -1; i < 3; i++) {
                  newBoard[row][column + i] = 1;
                }
                setPlacedShips([...placedShips, shipType]);
              }
              break;
            case 2:
              if (row !== 0 && row !== 7 && board[row - 1][column] === 0 && board[row + 1][column] === 0 && board[row][column] === 0) {
                for (let i = -2; i < 3; i++) {
                  if (row === 1 && column === 0 && i !== -2) {
                    newBoard[row + 2][column] = -1;
                    newBoard[row + i][column + 1] = -1;
                  }
                  if (row === 1 && 0 < column && column < 7 && i !== -2) {
                    newBoard[row + 2][column] = -1;
                    newBoard[row + i][column + 1] = -1;
                    newBoard[row + i][column - 1] = -1;
                  }
                  if (row === 1 && column === 7 && i !== -2) {
                    newBoard[row + 2][column] = -1;
                    newBoard[row + i][column - 1] = -1;
                  }
                  if (row === 6 && column === 0 && i !== 2) {
                    newBoard[row - 2][column] = -1;
                    newBoard[row + i][column + 1] = -1;
                  }
                  if (row === 6 && 0 < column && column < 7 && i !== 2) {
                    newBoard[row - 2][column] = -1;
                    newBoard[row + i][column + 1] = -1;
                    newBoard[row + i][column - 1] = -1;
                  }
                  if (row === 6 && column === 7 && i !== 2) {
                    newBoard[row - 2][column] = -1;
                    newBoard[row + i][column - 1] = -1;
                  }
                  if (row !== 6 && row !== 1 && column !== 0 && column !== 7) {
                    newBoard[row + i][column + 1] = -1;
                    newBoard[row + i][column - 1] = -1;
                    newBoard[row + i][column] = -1;
                  }
                  if (row !== 6 && row !== 1 && column === 0) {
                    newBoard[row + i][column + 1] = -1;
                    newBoard[row + i][column] = -1;
                  }
                  if (row !== 6 && row !== 1 && column === 7) {
                    newBoard[row + i][column - 1] = -1;
                    newBoard[row + i][column] = -1;
                  }
                }
                for (let i = -1; i < 2; i++) {
                  newBoard[row + i][column] = 1;
                }
                setPlacedShips([...placedShips, shipType]);
              }
              break;
            case 3:
              if (column !== 7 && board[row][column] === 0 && board[row][column + 1] === 0) {
                for (let i = -1; i < 3; i++) {
                  if (column === 0 && row === 0) {
                    newBoard[row + 1][column + i] = -1;
                    newBoard[row][column + 2] = -1;
                  }
                  if (row === 0 && column === 6 && i !== 2) {
                    newBoard[row][column - 1] = -1;
                    newBoard[row + 1][column + i] = -1;
                  }
                  if (row === 0 && 0 < column && column < 6) {
                    newBoard[row + 1][column + i] = -1;
                    newBoard[row][column + i] = -1;
                  }
                  if (row === 7 && column === 0) {
                    newBoard[row - 1][column + i] = -1;
                    newBoard[row][column + 2] = -1;
                  }
                  if (row === 7 && column === 6 && i !== 2) {
                    newBoard[row - 1][column + i] = -1;
                    newBoard[row][column - 1] = -1;
                  }
                  if (row === 7 && 0 < column && column < 6) {
                    newBoard[row - 1][column + i] = -1;
                    newBoard[row][column + i] = -1;
                  }
                  if (column === 0 && row !== 0 && row !== 7) {
                    newBoard[row][column + 2] = -1;
                    newBoard[row + 1][column + i] = -1;
                    newBoard[row - 1][column + i] = -1;
                  }
                  if (column === 6 && row !== 0 && row !== 7 && i !== 2) {
                    newBoard[row][column - 1] = -1;
                    newBoard[row + 1][column + i] = -1;
                    newBoard[row - 1][column + i] = -1;
                  }
                  if (row !== 0 && row !== 7 && column !== 0 && column !== 6) {
                    newBoard[row][column + i] = -1;
                    newBoard[row + 1][column + i] = -1;
                    newBoard[row - 1][column + i] = -1;
                  }
                }
                for (let i = 0; i < 2; i++) {
                  newBoard[row][column + i] = 1;
                }
                setPlacedShips([...placedShips, shipType]);
              }

              break;
            case 4:
              if (row !== 7 && board[row][column] === 0 && board[row + 1][column] === 0) {
                if (row === 0 && column === 0) {
                  newBoard[row][column + 1] = -1;
                  newBoard[row + 1][column + 1] = -1;
                  newBoard[row + 1][column] = -1;
                  newBoard[row + 2][column] = -1;
                  newBoard[row + 2][column + 1] = -1;
                }
                if (row === 0 && column === 7) {
                  newBoard[row][column - 1] = -1;
                  newBoard[row + 1][column - 1] = -1;
                  newBoard[row + 1][column] = -1;
                  newBoard[row + 2][column] = -1;
                  newBoard[row + 2][column - 1] = -1;
                }
                if (row === 7 && column === 0) {
                  newBoard[row][column + 1] = -1;
                  newBoard[row - 1][column + 1] = -1;
                  newBoard[row - 1][column] = -1;
                  newBoard[row - 2][column] = -1;
                  newBoard[row - 2][column + 1] = -1;
                }
                if (row === 6 && column === 7) {
                  newBoard[row][column - 1] = -1;
                  newBoard[row - 1][column - 1] = -1;
                  newBoard[row + 1][column - 1] = -1;
                  newBoard[row - 1][column] = -1;
                }
                if (column === 0 && 0 < row && row < 7) {
                  newBoard[row][column + 1] = -1;
                  newBoard[row + 1][column + 1] = -1;
                  newBoard[row - 1][column + 1] = -1;
                  newBoard[row - 1][column] = -1;
                  newBoard[row + 2][column] = -1;
                  newBoard[row + 2][column + 1] = -1;
                }
                if (column === 7 && 0 < row && row < 6) {
                  newBoard[row][column - 1] = -1;
                  newBoard[row + 2][column - 1] = -1;
                  newBoard[row - 1][column - 1] = -1;
                  newBoard[row - 1][column] = -1;
                  newBoard[row + 2][column] = -1;
                  newBoard[row + 1][column - 1] = -1;
                }
                if (row === 6 && 0 < column && column < 7) {
                  newBoard[row - 1][column - 1] = -1;
                  newBoard[row - 1][column + 1] = -1;
                  newBoard[row - 1][column] = -1;
                  newBoard[row][column - 1] = -1;
                  newBoard[row][column + 1] = -1;
                  newBoard[row + 1][column - 1] = -1;
                  newBoard[row + 1][column + 1] = -1;
                }
                if (row === 0 && 0 < column && column < 7) {
                  newBoard[row][column - 1] = -1;
                  newBoard[row][column + 1] = -1;
                  newBoard[row + 1][column - 1] = -1;
                  newBoard[row + 1][column + 1] = -1;
                  newBoard[row + 2][column] = -1;
                  newBoard[row + 2][column + 1] = -1;
                  newBoard[row + 2][column - 1] = -1;
                }
                if (row !== 0 && row !== 6 && column !== 0 && column !== 7) {
                  newBoard[row][column - 1] = -1;
                  newBoard[row][column + 1] = -1;
                  newBoard[row + 2][column - 1] = -1;
                  newBoard[row + 2][column + 1] = -1;
                  newBoard[row + 2][column] = -1;
                  newBoard[row - 1][column - 1] = -1;
                  newBoard[row - 1][column + 1] = -1;
                  newBoard[row - 1][column] = -1;
                  newBoard[row + 1][column + 1] = -1;
                  newBoard[row + 1][column - 1] = -1;
                  newBoard[row - 1][column] = -1;
                }
                newBoard[row][column] = 1;
                newBoard[row + 1][column] = 1;
                setPlacedShips([...placedShips, shipType]);
              }
              break;
            case 5:
              if (row === 0 && column === 0) {
                newBoard[row][column + 1] = -1;
                newBoard[row + 1][column + 1] = -1;
                newBoard[row + 1][column] = -1;
              }
              if (row === 0 && column === 7) {
                newBoard[row][column - 1] = -1;
                newBoard[row + 1][column - 1] = -1;
                newBoard[row + 1][column] = -1;
              }
              if (row === 7 && column === 0) {
                newBoard[row][column + 1] = -1;
                newBoard[row - 1][column + 1] = -1;
                newBoard[row - 1][column] = -1;
              }
              if (row === 7 && column === 7) {
                newBoard[row][column - 1] = -1;
                newBoard[row - 1][column - 1] = -1;
                newBoard[row - 1][column] = -1;
              }
              if (column === 0 && 0 < row && row < 7) {
                newBoard[row][column + 1] = -1;
                newBoard[row + 1][column + 1] = -1;
                newBoard[row - 1][column + 1] = -1;
                newBoard[row - 1][column] = -1;
                newBoard[row + 1][column] = -1;
              }
              if (column === 7 && 0 < row && row < 7) {
                newBoard[row][column - 1] = -1;
                newBoard[row + 1][column - 1] = -1;
                newBoard[row - 1][column - 1] = -1;
                newBoard[row - 1][column] = -1;
                newBoard[row + 1][column] = -1;
              }
              if (row === 7 && 0 < column && column < 7) {
                newBoard[row - 1][column - 1] = -1;
                newBoard[row - 1][column + 1] = -1;
                newBoard[row - 1][column] = -1;
                newBoard[row][column - 1] = -1;
                newBoard[row][column + 1] = -1;
              }
              if (row === 0 && 0 < column && column < 7) {
                newBoard[row][column - 1] = -1;
                newBoard[row][column + 1] = -1;
                newBoard[row + 1][column - 1] = -1;
                newBoard[row + 1][column + 1] = -1;
                newBoard[row + 1][column] = -1;
              }
              if (row !== 0 && row !== 7 && column !== 0 && column !== 7) {
                newBoard[row][column - 1] = -1;
                newBoard[row][column + 1] = -1;
                newBoard[row + 1][column - 1] = -1;
                newBoard[row + 1][column + 1] = -1;
                newBoard[row + 1][column] = -1;
                newBoard[row - 1][column - 1] = -1;
                newBoard[row - 1][column + 1] = -1;
                newBoard[row - 1][column] = -1;
              }

              newBoard[row][column] = 1;
              setPlacedShips([...placedShips, shipType]);
              break;
          }
          setBoard(newBoard);
        }
      });
    });
    if (newBoard.some((r) => r.some((c) => c === 1)) && shipType !== 0) {
      return true
    } else {
      return false
    }
  }

export const startNewGame = () => {
  window.location.reload();
}
