/* eslint-disable */

import Board from './Board'
import useGameStore from '../store/useGameStore'
import BombedShips from './BombedShips';
import Scoreboard from './Scoreboard';
import WinScreen from './WinScreen';
import LoseScreen from './LoseScreen';
import { sendAttack } from '../socket';

const StageThree = () => {
    const player = useGameStore(state => state.player);
    const winner = useGameStore(state => state.winner);
    const turn = useGameStore(state => state.turn);
    return (
        <>
            {
                winner === "" ?
                    <>
                        <div className='flex flex-col h-full justify-center items-end'>
                            <div className='w-[471px] flex items-center justify-start h-11 bg-slate-200/30 px-4 rounded-full  mb-10 mr-[122px] '>Place a bomb on opponent field {turn.toString() === player.toString() ? 'Your Turn ' : 'Enemy Turn'}</div>
                            <div className='flex gap-10 justify-center items-center' >
                                {/* <BombedShips /> */}
                                <div className='flex flex-col justify-center items-center'>
                                    <Board inGame={true} />
                                    <p className='text-[#256EA3] text-xl mt-4'>Your Board</p>
                                    <Scoreboard />
                                </div>
                                <div className='flex flex-col justify-center items-center'>
                                    <Board inGame={true} isEnemyBoard={true} />
                                    <p className='text-[#858585] text-xl mt-4'>Opponent's Board</p>
                                    <Scoreboard enemyBoard />
                                    
                                </div>
                                <button className='
                            bg-[#256EA3] w-32 h-10 rounded-md text-white mt-10 px-2 py-1 disabled:bg-[#256EA3] disabled:cursor-not-allowed disabled:opacity-50
                            '
                                        disabled={turn.toString() !== player.toString()}
                                        onClick={() => {
                                            sendAttack([0, 1])
                                        }
                                        }
                                    >
                                        Send Attack
                                    </button>
                                {/* <BombedShips reverse /> */}
                            </div>

                        </div>
                    </>
                    :
                    winner == player ?
                        <WinScreen />
                        :
                        <LoseScreen />
            }
        </>
    )
}

export default StageThree
