import { create } from "zustand";
type coordinates =
  | [[number, number], [number, number], [number, number], [number, number]]
  | [[number, number], [number, number], [number, number]]
  | [[number, number], [number, number]]
  | [[number, number], [number, number]]
  | [[number, number]];
type ShipStore = {
  shipType: number | 1 | 2 | 3 | 4 | 5;
  setShipType: (shipType: number | 1 | 2 | 3 | 4 | 5) => void;
  placedShips: number[];
  setPlacedShips: (placedShips: number[]) => void;
  placedShipsCoordinates: {
    [shipType: number]: coordinates | [number, number][];
  };

  setPlacedShipsCoordinates: (placedShipsCoordinates: {
    [shipType: number]: coordinates | [number, number][];
  }) => void;

  removeCoordinateFromPlacedShipsCoordinates: (
    shipType: number,
    coordinate: [number, number]
  ) => void;
};
const useShipStore = create<ShipStore>((set) => ({
  shipType: 0,
  setShipType: (shipType) => set({ shipType }),
  placedShips: [],
  setPlacedShips: (placedShips) => set({ placedShips }),
  placedShipsCoordinates: {},
  setPlacedShipsCoordinates: (placedShipsCoordinates) =>
    set((state) => ({
      placedShipsCoordinates: {
        ...state.placedShipsCoordinates,
        ...placedShipsCoordinates,
      },
    })),
  removeCoordinateFromPlacedShipsCoordinates: (shipType, coordinate) => {
    set((state) => {
      const coordinates = state.placedShipsCoordinates[shipType];
      if (coordinates) {
        const newCoordinates = coordinates.slice(1);
        return {
          placedShipsCoordinates: {
            ...state.placedShipsCoordinates,
            [shipType]: newCoordinates,
          },
        };
      }
      return state;
    });
  },
}));
export default useShipStore;
