import { create } from "zustand";

type UserNameStore = {
  name: string;
  setName: (name: string) => void;
};
const useUserNameStore = create<UserNameStore>((set) => ({
  name: "",
  setName: (name: string) => set((state) => ({ name })),
}));

export default useUserNameStore;
