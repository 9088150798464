import { create } from "zustand";

type GameStore = {
  player: string;
  setPlayer: (player: string) => void;
  enemy: string;
  setEnemy: (enemy: string) => void;
  turn: string;
  setTurn: (turn: string) => void;
  winner: string;
  setWinner: (winner: string) => void;
  resetPlayer: () => void;

  isPlayer: boolean;
  setIsPlayer: (isPlayer: boolean) => void;

  isGameReady: boolean;
  setIsGameReady: (isGameReady: boolean) => void;

  lastAttacked: any | null;
  setLastAttacked: (lastAttacked: any) => void;
  myAttack: any | null;
  setMyAttack: (myAttack: any) => void;

  hitCount: number;
  setHitCount: (hitCount: number) => void;
  enemyScore: number;
  setEnemyScore: (enemyHit: number) => void;
  playersInfo: string[];
  setPlayersInfo: (playersInfo: string[]) => void;
  spectatorModePlayers: string[];
  setSpectatorModePlayers: (spectatorModePlayers: string[]) => void;
  spectatorViewAttack: any[];
  setSpectatorViewAttack: (spectatorView: any[]) => void;
  myBoard: number[][];
  setMyBoard: (myBoard: number[][]) => void;
};

const useGameStore = create<GameStore>((set) => ({
  player: "",
  setPlayer: (player) => set({ player }),
  enemy: "",
  setEnemy: (enemy) => set({ enemy }),
  turn: "1",
  setTurn: (turn) => set({ turn }),
  winner: "",
  setWinner: (winner) => set({ winner }),
  resetPlayer: () => set({ player: "", enemy: "", turn: "1", winner: "" }),
  isPlayer: false,
  setIsPlayer: (isPlayer) => set({ isPlayer }),
  isGameReady: false,
  setIsGameReady: (isGameReady) => set({ isGameReady }),
  lastAttacked: null,
  setLastAttacked: (lastAttacked) => set({ lastAttacked }),
  myAttack: null,
  setMyAttack: (myAttack) => set({ myAttack }),
  hitCount: 0,
  setHitCount: (hitCount) => set({ hitCount }),
  enemyScore: 0,
  setEnemyScore: (enemyScore) => set({ enemyScore }),
  playersInfo: [],
  setPlayersInfo: (playersInfo) => set({ playersInfo }),
  spectatorModePlayers: [],
  setSpectatorModePlayers: (spectatorModePlayers) =>
    set({ spectatorModePlayers }),
  spectatorViewAttack: [],
  setSpectatorViewAttack: (spectatorViewAttack) => set({ spectatorViewAttack }),
  myBoard: Array.from({ length: 8 }, () => Array.from({ length: 8 }, () => 0)),
  setMyBoard: (myBoard) => set({ myBoard }),
}));

export default useGameStore;
