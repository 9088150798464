import icon from "../assets/octicon_question-16.svg"
import { useState } from "react";
import HowToPlayModal from "./HowToPlayModal";
const Header = () => {
    const [showModal, setShowModal] = useState(false);
    function handleHowToPlay() {
        setShowModal(
            (prev) => !prev
        );
    }

    return (
        <header className="h-[8vh] w-full bg-[#fff] flex justify-center items-center shadow-xl">
            <div className="w-9/12 flex justify-between items-center">
                <label className="font-bold text-[23px] text-[#256EA3]">Battleship</label>
                <div className="flex w-[110px] justify-between items-center">
                    <img src={icon} alt="icon" />
                    <button className=" font-medium text-[#256EA3]" onClick={handleHowToPlay}>How to Play</button>
                </div>
                {showModal && <HowToPlayModal setShowModal={setShowModal} showModal={showModal} />}
            </div>
        </header>
    );
}

export default Header
