import { create } from "zustand";

type BoardState = {
  board: number[][];
  setBoard: (board: number[][]) => void;
  resetBoard: () => void;
  enemyBoard: number[][];
  setEnemyBoard: (enemyBoard: number[][]) => void;
};
const useBoardStore = create<BoardState>((set) => ({
  board: Array.from({ length: 8 }, () => Array.from({ length: 8 }, () => 0)),
  enemyBoard: Array.from({ length: 8 }, () =>
    Array.from({ length: 8 }, () => 0)
  ),
  setEnemyBoard: (enemyBoard) => set({ enemyBoard }),
  setBoard: (board) => set({ board }),
  resetBoard: () =>
    set({
      board: Array.from({ length: 8 }, () =>
        Array.from({ length: 8 }, () => 0)
      ),
    }),
}));

export default useBoardStore;
