import useUserNameStore from "../store/useUserNameStore"
import JoinButton from "./JoinButton";

const NameInput = () => {
    const name = useUserNameStore(state => state.name);
    const setName = useUserNameStore(state => state.setName);
    return (
        <div className="w-screen h-screen">
            <div className="h-[92vh] bg-[#fff] flex justify-center items-center">
                <div className="h-[25%] w-[600px] flex flex-col justify-between">
                    <label className="text-[28px] text-[#256EA3] font-extrabold">ENTER GAME</label>
                    <div>
                        <label className="text-[20px] text-[#256EA3] font-medium">Create a username</label>
                        <div className="flex justify-around w-[90%] my-4 space-x-2">
                            <input placeholder="Enter a username for yourself" value={name} onChange={e => setName(e.target.value)} className="w-[75%] h-[60px] bg-[#EDEDED] text-center placeholder:text-center rounded-lg " />
                            <JoinButton />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default NameInput