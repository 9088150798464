import { create } from "zustand";

type StageStore = {
  stage: number | 1 | 2 | 3 | 4 | 5;
  incrementStage: () => void;
  decrementStage: () => void;
};
const useStageStore = create<StageStore>((set) => ({
  stage: 1,
  incrementStage: () => set((state) => ({ stage: state.stage + 1 })),
  decrementStage: () => set((state) => ({ stage: state.stage - 1 })),
}));

export default useStageStore;
