/* eslint-disable */
import SpectatorViewBoard from "./SpectatorViewBoard"
import useGameStore from "../store/useGameStore"
import SpectatorWinScreen from "./SpectatorWinScreen";
const SpectatorModeView = () => {
    const spectatorModePlayers = useGameStore(state => state.spectatorModePlayers);
    const winner = useGameStore(state => state.winner);
    const spectatorViewAttack = useGameStore(state => state.spectatorViewAttack);
    return (
        <>
            {
                winner === "" ? (
                    <div className="flex gap-10">
                        <SpectatorViewBoard playerName={spectatorModePlayers[0]} />
                        <SpectatorViewBoard playerName={spectatorModePlayers[1]} />
                    </div>
                ) :
                    <SpectatorWinScreen playerName={
                        winner == "1" ? spectatorModePlayers[0] : spectatorModePlayers[1]
                    } score={spectatorViewAttack[3] * 10} />
            }
        </>
    )
}

export default SpectatorModeView