
/* eslint-disable */
import icon_green from "../assets/play_button_green.svg"
import icon_grey from "../assets/play_button_grey.svg"
import useStageStore from '../store/useStageStore';
import useBoardStore from '../store/useBoardStore';
import useShipStore from '../store/useShipStore';
import useGameStore from '../store/useGameStore';
import Board from './Board';
import { sendBoard } from '../socket';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateLeft } from "@fortawesome/free-solid-svg-icons";
import { WaitingPage } from "./WaitingPage";
const GameBoard = () => {
    const incrementStage = useStageStore(state => state.incrementStage);
    const resetBoard = useBoardStore(state => state.resetBoard);
    const shipType = useShipStore(state => state.shipType);
    const setShipType = useShipStore(state => state.setShipType);
    const placedShips = useShipStore(state => state.placedShips);
    const setPlacedShips = useShipStore(state => state.setPlacedShips);
    const isGameReady = useGameStore(state => state.isGameReady);
    const board = useBoardStore(state => state.board);
    const handleShipPlacement = (shipType: number) => {
        setShipType(shipType)
    }

    const resetButton = () => {
        setShipType(0)
        setPlacedShips([])
        resetBoard()
    }
    return (
        <div className="flex justify-center items-center h-[92vh] w-screen">
            {
                !isGameReady ? (
                    <WaitingPage />
                ) : (
                    <>
                        <div className='flex flex-col w-7/12'>

                            <div className='bg-[#F8F8F8] rounded-3xl w-1/3 h-full px-10 py-4 text-start text-[#484848] font-light mb-14'>Arrange your ships on your board</div>

                            <div className='flex flex-row justify-between items-center'>

                                <div className='flex flex-col w-[25vh] '>

                                    {
                                        placedShips.length === 5
                                            ?
                                            <div></div>
                                            :
                                            <div className='w-full pb-5 text-[18px] font-[400] text-[#484848]'>Your Ships</div>
                                    }

                                    <button className='w-1/3 mb-10 p-2 bg-[#F8F8F8] rounded-lg font-medium flex justify-around' onClick={resetButton}>
                                        Reset
                                        <FontAwesomeIcon icon={faRotateLeft} />
                                    </button>

                                    <div className='flex flex-col items-center w-full justify-center'>
                                        {
                                            placedShips.length !== 5 ? (
                                                <div className='flex flex-col  justify-center items-center gap-4'>
                                                    {!placedShips.includes(1) && (
                                                        <button className={`flex justify-center items-center gap-[2px] hover:scale-105 transition-all ${shipType === 1 ? 'border-2 border-[#7B0B0B] rounded-full p-1' : 'border-none'}`}
                                                            onClick={() => handleShipPlacement(1)}
                                                        >
                                                            <div className='h-[4vh] w-[4vh] bg-[#256EA3] rounded-l-3xl ' />
                                                            <div className='h-[4vh] w-[4vh] bg-[#256EA3]' />
                                                            <div className='h-[4vh] w-[4vh]  bg-[#256EA3]' />
                                                            <div className='h-[4vh] w-[4vh]  bg-[#256EA3] rounded-r-3xl' />
                                                        </button>
                                                    )}
                                                    {
                                                        !placedShips.includes(2) && (
                                                            <button className={`flex flex-col justify-center items-center gap-[2px] hover:scale-105 transition-all ${shipType === 2 ? 'border-2 border-[#7B0B0B] rounded-full p-1' : 'border-none'}`}
                                                                onClick={() => handleShipPlacement(2)}
                                                            >
                                                                <div className='h-[4vh] w-[4vh] bg-[#256EA3] rounded-t-3xl' />
                                                                <div className='h-[4vh] w-[4vh] bg-[#256EA3]' />
                                                                <div className='h-[4vh] w-[4vh] bg-[#256EA3] rounded-b-3xl' />
                                                            </button>
                                                        )
                                                    }

                                                    {
                                                        !placedShips.includes(3) && (<button className={`flex  justify-center items-center gap-[2px] hover:scale-105 transition-all ${shipType === 3 ? 'border-2 border-[#7B0B0B] rounded-full p-1' : 'border-none'}`}
                                                            onClick={() => handleShipPlacement(3)}
                                                        >
                                                            <div className='h-[4vh] w-[4vh] bg-[#256EA3] rounded-l-3xl' />
                                                            <div className='h-[4vh] w-[4vh] bg-[#256EA3] rounded-r-3xl' />
                                                        </button >)
                                                    }
                                                    {
                                                        !placedShips.includes(4) && (
                                                            <button className={`flex flex-col justify-center items-center gap-[2px] hover:scale-105 transition-all ${shipType === 4 ? 'border-2 border-[#7B0B0B] rounded-full p-1' : 'border-none'}`}
                                                                onClick={() => handleShipPlacement(4)}
                                                            >
                                                                <div className='h-[4vh] w-[4vh] bg-[#256EA3] rounded-t-3xl' />
                                                                <div className='h-[4vh] w-[4vh] bg-[#256EA3] rounded-b-3xl' />
                                                            </button>
                                                        )
                                                    }
                                                    {!placedShips.includes(5) && (<button className={`hover:scale-105 transition-all ${shipType === 5 ? 'border-2 border-[#7B0B0B] rounded-3xl p-1' : 'border-none'}`}
                                                        onClick={() => handleShipPlacement(5)}
                                                    >
                                                        <div className='h-[4vh] w-[4vh] bg-[#256EA3] rounded-2xl' />
                                                    </button>)}
                                                </div>
                                            ) :
                                                (
                                                    <div>
                                                    </div>
                                                )
                                        }
                                    </div>

                                    {

                                            <div className='flex flex-col mt-20 justify-center items-center gap-4'>
                                                <button
                                                    className=' flex flex-row justify-around items-center w-full rounded-xl transition-all mt-4 text-[30px] text-[#36C555]'
                                                    onClick={() => {
                                                        incrementStage();
                                                        sendBoard(
                                                            Array.from({ length: 8 }, () => Array.from({ length: 8 }, () => 0))
                                                        );
                                                    }}
                                                >
                                                    <img src={icon_green} alt="icon" />
                                                    Ready To Start
                                                </button>
                                            </div>

                                    }

                                </div>


                                <Board />


                            </div>
                        </div>

                    </>
                )
            }

        </div >
    )
}

export default GameBoard