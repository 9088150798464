import useUserNameStore from "../store/useUserNameStore"
import player_icon from "../assets/player_image.svg"

export const WaitingPage = () => {
    const name = useUserNameStore(state => state.name);


    return (

        <div className="h-[92vh] w-7/12 flex flex-col justify-around items-center">

            <div className=" h-[45vh] w-full flex flex-col justify-around items-center">

                <div className="text-[#256EA3] font-semibold text-[36px]">
                    WAITING FOR PLAYER
                </div>

                <div className=" w-3/5 h-[50px] bg-green-10 text-[#484848] bg-[#F8F8F8] flex justify-center items-center rounded-3xl text-[20px] mb-9">
                    Please wait while another player enters the game
                </div>

                <div className="w-3/5 h-[100px] bg-[#F8F8F8] flex justify-between items-center rounded-full pl-4">

                    <div className=" w-1/5">
                        <img src={player_icon} alt="icon" />
                    </div>

                    <div className="w-4/5 font-medium text-xl">
                        {name}
                    </div>
                </div>


                <div className="w-3/5 h-[100px] bg-[#F8F8F8] flex justify-between items-center rounded-full pl-4">

                    <div className=" w-1/5 animate-pulse">
                        <img src={player_icon} alt="icon" className=" opacity-40" />
                    </div>

                    <div className="w-4/5 font-extralight text-[#8F8C8C] text-xl animate-bounce">
                        waiting to join...
                    </div>
                </div>
            </div>


        </div>

    );
}