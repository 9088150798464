
import useGameStore from "../store/useGameStore";
import useUserNameStore from "../store/useUserNameStore";

type ScoreBoardProps = {
    enemyBoard?: boolean
}
const Scoreboard: React.FC<ScoreBoardProps> = ({ enemyBoard }) => {
    const name = useUserNameStore(state => state.name);
    const hitCount = useGameStore(state => state.hitCount)
    const enemy = useGameStore(state => state.enemy)
    const enemyScore = useGameStore(state => state.enemyScore)
    return (
        <div
            style={{
                boxSizing: `border-box`,
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: `center`,
                    alignItems: `flex-end`,
                    height: `100%`,
                    // paddingBottom: `80px`,
                    gap: `50px`
                }}
            >
                <div style={{
                    display: `flex`,
                    width: '467px',
                    height: '107px',
                    top: '786px',
                    left: '209px',
                    backgroundColor: `#F8F8F8`,
                    padding: `20px 100px`,
                    borderRadius: `11px`,
                    gap: `65px`,
                    paddingLeft: '40px'
                }}>
                    <div
                        style={{
                            display: `flex`,
                            flexDirection: `column`,
                            color: `#256EA3`,
                            width: '129px',
                            height: '55px',
                            top: '812px',
                            left: '266px',
                        }}
                    >

                        <p style={{
                            width: '170px',
                            height: '20px',
                            top: '812px',
                            left: '266px',
                            font: 'poppins',
                            fontSize: '13px',
                            lineHeight: '19.5px',
                            fontWeight: '400'
                        }}>
                            {enemyBoard ? 'Opponent Score' : 'Your Score'}
                        </p>
                        <h2 style={{
                            fontSize: '23px',
                            fontWeight: '600',
                            font: 'poppins',
                            lineHeight: '40.5px',
                            width: '129px',
                            height: '35px',
                            top: '823px',
                            padding: '25px, 200px',
                            left: '266px'
                        }}>{enemyBoard ? enemy : name}</h2>
                    </div>
                    <div>
                    </div>
                    <p style={{/* 60 */
                        width: '75px',
                        height: '89px',
                        left: '476px',
                        top: '795px',
                        font: 'Poppins',
                        fontWeight: '400',
                        fontSize: '59px',
                        /* identical to box height */
                    }} className="score">{enemyBoard ? enemyScore * 10 : hitCount * 10 || 0}</p>
                </div>
            </div>
        </div>
    );
};
export default Scoreboard;