/* eslint-disable */

import React, { useState } from 'react'
import { handleBoardClick } from '../utils';
import useBoardStore from '../store/useBoardStore';
import useShipStore from '../store/useShipStore';
import { sendAttack } from '../socket';
import useGameStore from '../store/useGameStore';

type BoardProps = {
    inGame?: boolean
    isEnemyBoard?: boolean
}

const numArray = Array.from({ length: 8 }, (_, i) => i + 1);
const letterArray = Array.from({ length: 8 }, (_, i) => String.fromCharCode(65 + i));
const nullArray = Array.from({ length: 8 }, (_, i) => null)
const Board: React.FC<BoardProps> = ({
    inGame = false,
    isEnemyBoard = false
}
) => {
    const board = useBoardStore(state => state.board);
    // const board = useGameStore(state => state.bo);
    const setBoard = useBoardStore(state => state.setBoard);
    const shipType = useShipStore(state => state.shipType);
    const setShipType = useShipStore(state => state.setShipType);
    const placedShips = useShipStore(state => state.placedShips);
    const setPlacedShips = useShipStore(state => state.setPlacedShips);
    const enemyBoard = useBoardStore(state => state.enemyBoard);
    const player = useGameStore(state => state.player);
    const turn = useGameStore(state => state.turn);
    const setPlacedShipsCoordinates = useShipStore(state => state.setPlacedShipsCoordinates);
    const [hoverIndexes, setHoverIndexes] = useState<[number][number] | any>();
    const [rowValue, setRow] = useState<number>();
    const [colValue, setCol] = useState<number>();
    const [hover, setHover] = useState("");
    const [errHover, setErrHover] = useState("");
    const myBoard = useGameStore(state => state.myBoard);

    const MouseOver = (rowIndex: number, colIndex: number) => {
        setErrHover(() => "bg-[#8F0C0C33]/10")
        setHover(() => "bg-[#256EA357]/30")



        if (shipType === 1) {
            const newArray = Array(4).fill([]);
            for (let i = 0; i < newArray.length; i++) {
                newArray[i] = colIndex + (i - 1);
            }

            for (let i = 0; i < newArray.length; i++) {
                if (board[rowIndex][newArray[i]] === 1 || board[rowIndex][newArray[i]] === -1) {

                }
            }

            setHoverIndexes(() => newArray);
            setRow(() => rowIndex)
        }

        if (shipType === 2) {
            const newArray = Array(3).fill([]);
            for (let i = 0; i < newArray.length; i++) {
                newArray[i] = rowIndex + (i - 1);
            }


            setHoverIndexes(() => newArray);
            setCol(() => colIndex)

        }

        if (shipType === 3) {

            const newArray = Array(2).fill([]);
            for (let i = 0; i < newArray.length; i++) {
                newArray[i] = colIndex + (i);
            }
            setHoverIndexes(() => newArray);
            setRow(() => rowIndex)
        }

        if (shipType === 4) {
            const newArray = Array(2).fill([]);
            for (let i = 0; i < newArray.length; i++) {
                newArray[i] = rowIndex + (i);
            }

            setHoverIndexes(() => newArray);
            setCol(() => colIndex)
        }

        if (board[rowIndex][colIndex] === 1 || board[rowIndex][colIndex] === -1) {
            const newArray = Array(1).fill(-1);
            setHoverIndexes(() => [...newArray])
        }
    }


    const MouseLeave = () => {
        setHover(() => "")
        setErrHover(() => "");
        setHoverIndexes(() => Array(0).fill([]));
    }


    return (

        <div className={`flex w-[50vh] h-[50vh] items-center rounded-2xl ${isEnemyBoard ? 'bg-[#e8e8e8]' : 'bg-[#EFF6FB]'} justify-center`}>

            <div className='flex items-center justify-center flex-col'>
                <div className='flex flex-row ml-[5vh] mr-[5vh]'>
                    {letterArray.map((letter, index) => (
                        <label key={index} className={`flex justify-center items-center w-[5vh] h-[5vh] ${isEnemyBoard ? 'text-[#858585]' : 'text-[#256EA3]'}`}>{letter}</label>
                    ))}
                </div>
                <div className='flex'>
                    <div className='flex flex-col'>
                        {numArray.map((t, index) => (
                            <label key={index} className={`flex justify-center items-center w-[5vh] h-[5vh]
                             ${isEnemyBoard ? 'text-[#858585]' : 'text-[#256EA3]'}`}>{t}</label>
                        ))}
                    </div>
                    {isEnemyBoard ? (
                        <div>
                            {enemyBoard?.map((row, rowIndex) => (
                                <div className='flex justify-center items-center' key={rowIndex}>
                                    {row.map((column, columnIndex) => (
                                        <button disabled={player != turn}
                                            onClick={
                                                () => {
                                                    if (enemyBoard[rowIndex][columnIndex] === 0 || enemyBoard[rowIndex][columnIndex] === -1) {
                                                        sendAttack([rowIndex, columnIndex])
                                                    }
                                                }
                                            }
                                            key={columnIndex} className={`flex justify-center items-center hover:bg-[#256EA357]  w-[5vh] h-[5vh] bg-[#e8e8e8]/30 border border-[#858585] border-collapse
                                            `}>
                                            {
                                                column === 1 ? '' : column === 2 ?
                                                    <div className='w-full h-full bg-[#BFBFBF] hover:bg-none flex items-center justify-center' >
                                                        <div className='text-black font-extrabold text-[3vw]'>
                                                            <p>
                                                                &#10539;
                                                            </p>
                                                        </div>
                                                    </div>
                                                    : column === 3 ? <div className='w-full h-full bg-[#BFBFBF] flex items-center justify-center' />
                                                        : ''
                                            }
                                        </button>
                                    ))}
                                </div>
                            ))}
                        </div>
                    ) :
                        (
                            <div>
                                {myBoard?.map((row, rowIndex) => (
                                    <div className='flex justify-center items-center' key={rowIndex}>
                                        {row.map((column, columnIndex) => (
                                            (hoverIndexes?.includes(columnIndex) && (hoverIndexes?.includes(-1) ||
                                                hoverIndexes?.includes(8)) && rowValue === rowIndex) && (shipType === 1
                                                    || shipType === 3)
                                                ?

                                                (<button disabled={inGame === true}
                                                    onClick={
                                                        () => {
                                                            if (inGame === false) {
                                                                if (handleBoardClick(rowIndex, columnIndex, shipType, board, placedShips, setBoard, setPlacedShips)) {
                                                                    setPlacedShipsCoordinates({
                                                                        [shipType]: shipType === 1 ? [[rowIndex, columnIndex - 1], [rowIndex, columnIndex], [rowIndex, columnIndex + 1], [rowIndex, columnIndex + 2]] : shipType === 3 ? [[rowIndex, columnIndex], [rowIndex, columnIndex + 1]] : shipType === 4 ? [[rowIndex, columnIndex], [rowIndex + 1, columnIndex]] : [[rowIndex, columnIndex]]
                                                                    })
                                                                }
                                                                setShipType(0)
                                                            } else {
                                                            }
                                                        }
                                                    }
                                                    onMouseOver={() => MouseOver(rowIndex, columnIndex)}
                                                    onMouseLeave={() => MouseLeave()}
                                                    key={columnIndex} className={`flex justify-center items-center border-[1px] border-[#256EA3] border-collapse ${errHover}
                                                    
                                                    ${inGame ? 'h-[5vh] w-[5vh] ' : 'h-[5vh] w-[5vh]'}`}>
                                                    {
                                                        column === 1 ? <div className='h-[4vh] w-[4vh] bg-[#256EA3] rounded-2xl' /> : column === 2 ? <div className='w-full h-full bg-[#BFBFBF] hover:bg-none flex items-center justify-center' >
                                                            <div className='text-black font-extrabold text-[3vw]'>
                                                                <p>
                                                                    &#10539;
                                                                </p>
                                                            </div>
                                                        </div> : column === 3 ? <div className='w-full h-full bg-[#BFBFBF] flex items-center justify-center' /> : ""
                                                    }
                                                </button>)

                                                // Dikey Buton Hata Kontrol

                                                : (hoverIndexes?.includes(rowIndex) && (hoverIndexes?.includes(-1) || hoverIndexes?.includes(8)) &&
                                                    colValue === columnIndex) &&
                                                    (shipType === 2 || shipType === 4)
                                                    ?

                                                    (<button disabled={inGame === true}
                                                        onClick={
                                                            () => {
                                                                if (inGame === false) {
                                                                    if (handleBoardClick(rowIndex, columnIndex, shipType, board, placedShips, setBoard, setPlacedShips)) {
                                                                        setPlacedShipsCoordinates({
                                                                            [shipType]: shipType === 2 ? [[rowIndex - 1, columnIndex], [rowIndex, columnIndex], [rowIndex + 1, columnIndex]] : shipType === 4 ? [[rowIndex, columnIndex], [rowIndex + 1, columnIndex]] : [[rowIndex, columnIndex]]
                                                                        })
                                                                    }
                                                                    setShipType(0)
                                                                } else {
                                                                }
                                                            }
                                                        }
                                                        onMouseOver={() => MouseOver(rowIndex, columnIndex)}
                                                        onMouseLeave={() => MouseLeave()}
                                                        key={columnIndex} className={`flex justify-center items-center border-[1px] border-[#256EA3] border-collapse ${errHover}
                                                    
                                                    ${inGame ? 'h-[5vh] w-[5vh] ' : 'h-[5vh] w-[5vh]'}
`}>
                                                        {
                                                            column === 1 ? <div className='h-[4vh] w-[4vh] bg bg-[#256EA3] rounded-2xl' /> : column === 2 ? <div className='w-full h-full bg-[#BFBFBF] hover:bg-none flex items-center justify-center' >
                                                                <div className='text-black font-extrabold text-6xl'>
                                                                    <p>
                                                                        &#10539;
                                                                    </p>
                                                                </div>
                                                            </div> : column === 3 ? <div className='w-full h-full bg-[#BFBFBF] flex items-center justify-center' /> : ""
                                                        }
                                                    </button>)

                                                    // Yatay Buton hover Efekt
                                                    : (hoverIndexes?.includes(columnIndex) &&
                                                        rowValue === rowIndex) && (shipType === 1
                                                            || shipType === 3)
                                                        ?

                                                        (<button disabled={inGame === true}
                                                            onClick={
                                                                () => {
                                                                    if (inGame === false) {
                                                                        if (handleBoardClick(rowIndex, columnIndex, shipType, board, placedShips, setBoard, setPlacedShips)) {
                                                                            setPlacedShipsCoordinates({
                                                                                [shipType]: shipType === 1 ? [[rowIndex, columnIndex - 1], [rowIndex, columnIndex], [rowIndex, columnIndex + 1], [rowIndex, columnIndex + 2]] : shipType === 3 ? [[rowIndex, columnIndex], [rowIndex, columnIndex + 1]] : shipType === 4 ? [[rowIndex, columnIndex], [rowIndex + 1, columnIndex]] : [[rowIndex, columnIndex]]
                                                                            })
                                                                        }
                                                                        setShipType(0)
                                                                    } else {
                                                                    }
                                                                }
                                                            }
                                                            onMouseOver={() => MouseOver(rowIndex, columnIndex)}
                                                            onMouseLeave={() => MouseLeave()}
                                                            key={columnIndex} className={`flex justify-center items-center border-[1px] border-[#256EA3] border-collapse ${hover}
                                                            ${board[rowIndex][columnIndex] === -1 ? "bg-[#8F0C0C33]/10" : ""}
                                                        ${inGame ? 'h-[5vh] w-[5vh] ' : 'h-[5vh] w-[5vh]'}`}>
                                                            {
                                                                column === 1 ? <div className='h-[4vh] w-[4vh] bg bg-[#256EA3] rounded-2xl' /> : column === 2 ? <div className='w-full h-full bg-[#BFBFBF] hover:bg-none flex items-center justify-center' >
                                                                    <div className='text-black font-extrabold text-6xl'>
                                                                        <p>
                                                                            &#10539;
                                                                        </p>
                                                                    </div>
                                                                </div> : column === 3 ? <div className='w-full h-full bg-[#BFBFBF] flex items-center justify-center' /> : ""
                                                            }
                                                        </button>)


                                                        // Dikey Buton Hover Efekt
                                                        : (hoverIndexes?.includes(rowIndex) && colValue === columnIndex)
                                                            && (shipType === 2
                                                                || shipType === 4)

                                                            ?

                                                            (<button disabled={inGame === true}
                                                                onClick={
                                                                    () => {
                                                                        if (inGame === false) {
                                                                            if (handleBoardClick(rowIndex, columnIndex, shipType, board, placedShips, setBoard, setPlacedShips)) {
                                                                                setPlacedShipsCoordinates({
                                                                                    [shipType]: shipType === 2 ? [[rowIndex - 1, columnIndex], [rowIndex, columnIndex], [rowIndex + 1, columnIndex]] : shipType === 4 ? [[rowIndex, columnIndex], [rowIndex + 1, columnIndex]] : [[rowIndex, columnIndex]]
                                                                                })
                                                                            }
                                                                            setShipType(0)
                                                                        } else {
                                                                        }
                                                                    }
                                                                }
                                                                onMouseOver={() => MouseOver(rowIndex, columnIndex)}
                                                                onMouseLeave={() => MouseLeave()}
                                                                key={columnIndex} className={`flex justify-center items-center border-[1px] border-[#256EA3] border-collapse ${hover}
                                                    ${board[rowIndex][columnIndex] === -1 ? "bg-[#8F0C0C33]/10" : ""}
                                                    ${inGame ? 'h-[5vh] w-[5vh] ' : 'h-[5vh] w-[5vh]'}`}>
                                                                {
                                                                    column === 1 ? <div className='h-[4vh] w-[4vh] bg bg-[#256EA3] rounded-2xl' /> : column === 2 ? <div className='w-full h-full bg-[#BFBFBF] hover:bg-none flex items-center justify-center' >
                                                                        <div className='text-black font-extrabold text-6xl'>
                                                                            <p>
                                                                                &#10539;
                                                                            </p>
                                                                        </div>
                                                                    </div> : column === 3 ? <div className='w-full h-full bg-[#BFBFBF] flex items-center justify-center' /> : ""
                                                                }
                                                            </button>)

                                                            // Teklik Gemi efekt ve hata
                                                            : shipType === 5 ?

                                                                (<button disabled={inGame === true}
                                                                    onClick={
                                                                        () => {
                                                                            if (inGame === false) {
                                                                                if (handleBoardClick(rowIndex, columnIndex, shipType, board, placedShips, setBoard, setPlacedShips)) {
                                                                                    setPlacedShipsCoordinates({
                                                                                        [shipType]: shipType === 5 ? [[rowIndex, columnIndex]] : [[rowIndex, columnIndex]]
                                                                                    })
                                                                                }
                                                                                setShipType(0)
                                                                            } else {
                                                                            }
                                                                        }
                                                                    }
                                                                    onMouseOver={() => MouseOver(rowIndex, columnIndex)}
                                                                    onMouseLeave={() => MouseLeave()}
                                                                    key={columnIndex} className={`flex justify-center items-center border-[1px] border-[#256EA3] border-collapse 
                                                                    ${board[rowIndex][columnIndex] === -1 ? "bg-[#8F0C0C33]/10" : ""}
                                                    ${inGame ? 'h-[5vh] w-[5vh] ' : 'h-[5vh] w-[5vh]'}
                                                    ${column === 0 ? "hover:bg-[#256EA357]/30" : column === -1 ? "hover:bg-[#8F0C0C33]/10" : ""}`}>
                                                                    {
                                                                        column === 1 ? <div className='h-[4vh] w-[4vh] bg bg-[#256EA3] rounded-2xl' /> : column === 2 ? <div className='w-full h-full bg-[#BFBFBF] hover:bg-none flex items-center justify-center' >
                                                                            <div className='text-black font-extrabold text-6xl'>
                                                                                <p>
                                                                                    &#10539;
                                                                                </p>
                                                                            </div>
                                                                        </div> : column === 3 ? <div className='w-full h-full bg-[#BFBFBF] flex items-center justify-center' /> : ""
                                                                    }
                                                                </button>)

                                                                :
                                                                (<button disabled={shipType === 0 || inGame === true}
                                                                    onClick={
                                                                        () => {
                                                                            if (inGame === false) {
                                                                                if (handleBoardClick(rowIndex, columnIndex, shipType, board, placedShips, setBoard, setPlacedShips)) {
                                                                                    setPlacedShipsCoordinates({
                                                                                        [shipType]: shipType === 1 ? [[rowIndex, columnIndex - 1], [rowIndex, columnIndex], [rowIndex, columnIndex + 1], [rowIndex, columnIndex + 2]] : shipType === 2 ? [[rowIndex - 1, columnIndex], [rowIndex, columnIndex], [rowIndex + 1, columnIndex]] : shipType === 3 ? [[rowIndex, columnIndex], [rowIndex, columnIndex + 1]] : shipType === 4 ? [[rowIndex, columnIndex], [rowIndex + 1, columnIndex]] : [[rowIndex, columnIndex]]
                                                                                    })
                                                                                }
                                                                                setShipType(0)
                                                                            } else {
                                                                            }
                                                                        }
                                                                    }
                                                                    onMouseOver={() => MouseOver(rowIndex, columnIndex)}
                                                                    onMouseLeave={() => MouseLeave()}
                                                                    key={columnIndex} className={`flex justify-center items-center border-[1px] border-[#256EA3] border-collapse 
                                                    ${column === -1 ? "bg-[#8F0C0C33]/10" : ""}
                                                    ${inGame ? 'h-[5vh] w-[5vh] ' : 'h-[5vh] w-[5vh]'}`}>
                                                                    {
                                                                        column === 1 ? <div className='h-[4vh] w-[4vh] bg bg-[#256EA3] rounded-xl' /> : column === 2 ? <div className='w-full h-full bg-[#BFBFBF] hover:bg-none flex items-center justify-center' >
                                                                            <div className='text-black font-extrabold text-6xl'>
                                                                                <p>
                                                                                    &#10539;
                                                                                </p>
                                                                            </div>
                                                                        </div> : column === 3 ? <div className='w-full h-full bg-[#BFBFBF] flex items-center justify-center' /> : ""
                                                                    }
                                                                </button>)
                                        ))}
                                    </div>
                                ))}
                            </div>
                        )}


                    <div className='flex flex-col'>
                        {nullArray.map((t, index) => (
                            <label key={index} className='flex justify-center items-center h-[5vh] w-[5vh] text-[#256EA3]'>{t}</label>
                        ))}
                    </div>


                </div >

                <div className='flex flex-row ml-[5vh] mr-[5vh]'>
                    {nullArray.map((letter, index) => (
                        <label key={index} className='flex justify-center items-center h-[5vh] w-[5vh] text-[#256EA3]'>{letter}</label>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Board
