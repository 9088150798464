import GameBoard from "./GameBoard"
import useGameStore from "../store/useGameStore"
import SpectatorModeView from "./SpectatorModeView";
const StageTwo = () => {
    const isPlayer = useGameStore(state => state.isPlayer);
    return (
        <div>
            {isPlayer ? <GameBoard /> : <SpectatorModeView />}
        </div>
    )
}

export default StageTwo