import { Socket, io } from "socket.io-client";

let socket: Socket;
export const initiateSocketConnection = () => {
  socket = io(":8086", {
    transports: ["websocket"],
    autoConnect: false,
  });
};
export const startConnection = () => {
  socket.connect();
};

export const disconnectSocket = () => {
  if (socket) socket.disconnect();
};

export const gameFinish = (winner: string) => {
  if (socket) socket.emit("game-finish", winner);
};
export const joinRoom = (name: string) => {
  if (socket) socket.emit("join", name);
};

export const changeTurn = (turn: string) => {
  if (socket) socket.emit("change-turn", turn);
};

export const sendBoard = (board: number[][]) => {
  if (socket) socket.emit("send-board");
};
export const sendAttack = (attack: number[]) => {
  if (socket) socket.emit("send-attack", attack);
};

export const changeTurnListener = (cb: (turn: string) => void) => {
  socket.on("change-turn", (data: string) => {
    cb(data);
  });
};
export const updateSpecBoardsListener = (cb: (boards: any) => void) => {
  socket.on("update-spec-boards", (data: any) => {
    cb(data);
  });
};
export const gameFinishListener = (cb: (winner: string) => void) => {
  socket.on("game-finish", (data: string) => {
    cb(data);
  });
};
export const receiveAttackListener = (cb: (attack: any) => void) => {
  socket.on("receive-attack", (data: any) => {
    cb(data);
  });
};

export const myAttackListener = (cb: (attack: any) => void) => {
  socket.on("my-attack", (data: any) => {
    cb(data);
  });
};

export const receiveBoardLister = (cb: (board: number[][]) => void) => {
  socket.on("receive-board", (data: number[][]) => {
    cb(data);
  });
};

export const userJoinedListener = (cb: any) => {
  socket.on("user-joined", (data: any) => {
    cb(data);
  });
};

export const spectatorJoinedListener = (cb: any) => {
  socket.on("spectator-joined", (data: any) => {
    cb(data);
  });
};

export const gameReadyListener = (cb: any) => {
  socket.on("game-ready", (data: any) => {
    cb(data);
  });
};

export const removeChangeTurnListener = () => {
  socket.off("change-turn", (data: any) => {});
};

export const removeGameReadyListener = () => {
  socket.off("game-ready", (data: any) => {});
};
export const removeUserListener = () => {
  socket.off("remove-user", (data: any) => {});
};

export const removeSpectatorListener = () => {
  socket.off("remove-spectator", (data: any) => {});
};

export const removeReceiveBoardListener = () => {
  socket.off("receive-board", (data: any) => {});
};

export const removeReceiveAttackListener = () => {
  socket.off("receive-attack", (data: any) => {});
};

export const removeGameFinishListener = () => {
  socket.off("game-finish", (data: any) => {});
};
export const removeUpdateSpecBoardsListener = () => {
  socket.off("update-spec-boards", (data: any) => {});
};

export const removeMyAttackListener = () => {
  socket.off("my-attack", (data: any) => {});
};
